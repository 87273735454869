import * as React from "react"
import Helmet from "react-helmet";
import Footer from "../components/footer/footer";
import Header from "../components/header/header"

// markup
const IndexPage = () => {

  return (
    <>
     <Helmet>
        <meta charSet="utf-8" />
        <title>The Detailing Ghost | Fully Mobile | We Come To You</title>
        <meta name="description" content="Give your car that extra care and protection it truly deserves. We provide premium car cleaning &amp; detailing services. Give us a call to book yours today." />
        <link rel="canonical" href="https://thedetailingghost.co.nz" />
      </Helmet>
      <Header style="static" />
      <main className="main">
      <div className="container">
      <br /><br />
        <h1 className="heading">Terms & Conditions</h1><br />
        <p>The following sets forth the terms and conditions under which The Detailing Ghost will perform services on your vehicle:<br /><br /></p>
        <ul>
          <li><strong>Best Efforts Basis:</strong> Our services are provided on a best efforts basis – we will not be held responsible in the event that we are unable to clean, remove stains, or otherwise restore any surface, fabric, or condition on your vehicle. All services listed in our marketing material are for reference only, and do necessarily represent a guarantee that the service was performed depending upon the condition of your vehicle or upon a special extenuating circumstance.<br /><br /></li>
          <li><strong>Customer Satisfaction:</strong> Customers must notify us within 48 hours of job completion if they are not satisfied with the PPF (Paint Protection Film) or Ceramic Coating services provided, failing which acceptance of the completed job will be assumed, and any subsequent requests for adjustments or rectifications may be subject to additional charges at our discretion.<br /><br /></li>
          <li><strong>Incidental Damage:</strong> We are not responsible for any incidental damage that may occur while your vehicle is in our possession, including but not limited to any damage related to pre‐existing conditions (tears, scratches, dents, stains, odors, etc.) damage to automated windshield wiper systems, wiper blades, rear window wipers, loose interior trim or exterior moldings, exterior mirrors, trailer hitch caps, roof racks, power antennas, insignias, aftermarket accessories (bug shields, sun visors), magnetic signage, running boards, hood ornaments, or alloy, chrome or mag wheels, headlights, rear lamps, or reflectors. We are responsible for any damage we cause while driving your vehicle and for anything we break or damage in the course of providing our service, so long as the damage did not involve a prior condition.<br /><br /></li>
          <li><strong>Additional Charges:</strong> In the course of rendering services on your vehicle, we may uncover conditions or circumstances that require additional effort or time, and therefore you may incur additional cost, to properly complete your vehicle service. We will make every reasonable effort to notify you in advance of completing the service, however in the event we are unable to contact you for any reason out of our control, we reserve the right to charge our published hourly rate to complete the work and maintain circulation of our work in process inventory.<br /><br /></li>
          <li><strong>Possessions Left in the Vehicle:</strong> Our policy is to gather all of the vehicle owners’ belongings that are left in the vehicle and place them in a bag to be provided to the customer when their vehicle service is complete. Our staff is also trained to remove any loose change from the console area and place it in a plastic bag to facilitate cleaning. We are not responsible for any belongings that may be lost or otherwise disposed of in the course of cleaning your vehicle. We strongly recommend that each customer remove any belongings they consider to be of value before leaving the vehicle with us.<br /><br /></li>
          <li><strong>Engine Damage Disclaimer:</strong> Certain detailing services include cleaning of the engine compartment and components. The Detailing Ghost is not responsible for any damage resulting from this service, temporary or otherwise, that may as a result from water interfering with engine function.<br /><br /></li>
          <li><strong>Limit of Liability:</strong> The Detailing Ghost’s entire liability for providing services is limited to the invoice amount for the service we provide, except for any damages that result from collisions that take place while the vehicle is in our possession.<br /><br /></li>
          <li><strong>Coupons, Discounts and Gift Certificates:</strong> The Detailing Ghost will only honor coupons, discounts, and gift certificates that are presented prior to performing services.<br /><br /></li>
          <li><strong>Cancellation:</strong> No cancellations or changes allowed within 24 hours of the appointment.<br /><br /></li><br />
        </ul>
      </div>
      </main>
      <Footer />
    </>
  )
}

export default IndexPage
